import "../css/main.css";
import { Elm } from "../src/Main.elm";
import { initializeApp } from "firebase/app";
// import { getAuth, isSignInWithEmailLink, signInWithEmailLink, sendSignInLinkToEmail, signOut, browserSessionPersistence, setPersistence } from "firebase/auth";
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    browserSessionPersistence,
    setPersistence,
} from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDL61EKTxaiVuZp00uo38666zBa0bT_Dhw",
    authDomain: "yogazentrum-kroesselbach.firebaseapp.com",
    projectId: "yogazentrum-kroesselbach",
    storageBucket: "yogazentrum-kroesselbach.appspot.com",
    messagingSenderId: "274916623368",
    appId: "1:274916623368:web:912ccd2dda0683bbb33dbd",
    databaseURL:
        "https://yogazentrum-kroesselbach-default-rtdb.europe-west1.firebasedatabase.app",
};

const firebaseApp = initializeApp(firebaseConfig);

// Confirm the link is a sign-in with email link.
const auth = getAuth(firebaseApp);
const db = getDatabase(firebaseApp);

let lastUser = undefined;

// const actionCodeSettings = {
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be in the authorized domains list in the Firebase Console.
//   url: 'localhost:1234/login?cartId=1234',
//   // This must be true.
//   handleCodeInApp: true,
//   // dynamicLinkDomain: 'example.page.link'
// };

// const sendEmailLink = (email, succ, fail) => {
//     sendSignInLinkToEmail(auth, email, {
//       // URL you want to redirect back to. The domain (www.example.com) for this
//       // URL must be in the authorized domains list in the Firebase Console.
//       url: window.location.href,
//       // This must be true.
//       handleCodeInApp: true,
//       // dynamicLinkDomain: 'example.page.link'
//     })
//       .then(() => {
//         // The link was successfully sent. Inform the user.
//         // Save the email locally so you don't need to ask the user for it again
//         // if they open the link on the same device.
//         window.localStorage.setItem('emailForSignIn', email);
//         // ...
//         succ()
//       })
//       .catch((error) => {
//         // const errorCode = error.code;
//         // const errorMessage = error.message;
//         fail(error.message)
//         // ...
//       });
// }

// const loginWithEmailLink = (succ, fail) => {
//     console.log("loginWithEmailLink", auth, isSignInWithEmailLink(auth, window.location.href))
//     if (isSignInWithEmailLink(auth, window.location.href)) {
//       // Additional state parameters can also be passed via URL.
//       // This can be used to continue the user's intended action before triggering
//       // the sign-in operation.
//       // Get the email if available. This should be available if the user completes
//       // the flow on the same device where they started it.
//       let email = window.localStorage.getItem('emailForSignIn');
//       if (!email) {
//         // User opened the link on a different device. To prevent session fixation
//         // attacks, ask the user to provide the associated email again. For example:
//         email = window.prompt('Please provide your email for confirmation');
//       }
//       // The client SDK will parse the code from the link for you.
//       setPersistence(auth, browserSessionPersistence)
//         .then( () => signInWithEmailLink(auth, email, window.location.href))
//         .then((result) => {
//           // Clear email from storage.
//           window.localStorage.removeItem('emailForSignIn');
//           // You can access the new user via result.user
//           // Additional user info profile not available via:
//           // result.additionalUserInfo.profile == null
//           // You can check if the user is new or existing:
//           // result.additionalUserInfo.isNewUser
//           succ(result)
//         })
//         .catch((error) => {
//           // Some error occurred, you can inspect the code: error.code
//           // Common errors could be invalid email and invalid or expired OTPs.
//           fail(error.message)
//         });
//     } else {
//         fail("No signin with email link allowed")
//     }
// }

const loginWithEmail = (email, password, succ, fail) => {
    // console.log("loginWithEmail", auth, email, password);
    // The client SDK will parse the code from the link for you.
    setPersistence(auth, browserSessionPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password))
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            succ(user);
            // ...
        })
        .catch((error) => {
            fail(`${error.code}: ${error.message}`);
        });
};

const logoutFirebase = (succ, fail) => {
    signOut(auth)
        .then(() => {
            // Sign-out successful.
            // Clear email from storage.
            // window.localStorage.removeItem('emailForSignIn');
            succ();
        })
        .catch((error) => {
            // An error happened.
            fail(error);
        });
};

const isAdmin = (user, cb) => {
    return onValue(
        ref(db, "/admins/" + user.uid),
        (isAdmin) => {
            if (isAdmin.val() || false) {
                cb();
            }
        },
        {
            onlyOnce: true,
        }
    );
};

const loadPreviewKey = (handleKey) => {
    return onValue(
        ref(db, "/contentfulPreviewKey"),
        (remoteKey) => {
            const key = remoteKey.val();
            // console.log("Key", key)
            handleKey(key);
        },
        {
            onlyOnce: true,
        }
    );
};

let app = Elm.Main.init({
    node: document.querySelector("main"),
    flags: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
});

// app?.ports?.sendEmailLink?.subscribe( function(email) {
//     console.log("sendEmailLink")
//     sendEmailLink(email, (result) => {
//         console.log("sendEmailLink success", result)
//         app?.ports?.sendEmailLinkSuccess(result)
//     },
//     (error) =>  {
//         console.log("sendEmailLink  error", error)
//         app?.ports?.sendEmailLinkError(error)
//     })
// })

// app?.ports?.requestLoginWithLink?.subscribe( function() {
//     console.log("requestLoginWithLink")
//     loginWithEmailLink( (result) => {
//         console.log("requestLoginWithLink success", result)
//         app?.ports?.loginSuccess(result)
//     },
//     (error) =>  {
//         console.log("requestLoginWithLink  error", error)
//         app?.ports?.loginError(error)
//     })
// })

app?.ports?.requestLoginWithEmailPort?.subscribe(function ({
    email,
    password,
}) {
    console.log("requestLoginWithEmail", email, password);
    loginWithEmail(
        email,
        password,
        (result) => {
            console.log("requestLoginWithEmail success", result);
            app?.ports?.loginSuccess?.send(result);
        },
        (error) => {
            console.log("requestLoginWithEmail  error", error);
            app?.ports?.loginError?.send(error);
        }
    );
});

app?.ports?.requestLogout?.subscribe(function () {
    console.log("requestLogout");
    logoutFirebase(
        (result) => {
            console.log("requestLogout success", result);
            app?.ports?.logoutSuccess?.send(result);
        },
        (error) => {
            console.log("requestLogout error", error);
            app?.ports?.logoutError?.send(error);
        }
    );
});

const encode = (data) => {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
};

app?.ports?.sendContactFormPort?.subscribe(function (formFields) {
    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formFields }),
    })
        .then((response) => {
            if (response.ok) {
                // console.log("sendContactFormSuccess");
                app?.ports?.sendContactFormSuccess?.send();
            } else {
                // console.log("sendContactFormError1", response);
                app?.ports?.sendContactFormError?.send(
                    response.statusText || "unknown error"
                );
            }
        })
        .catch((error) => {
            // console.log("sendContactFormError2", error);
            app?.ports?.sendContactFormError?.send(error);
        });
});

// if (auth?.currentUser) {
//     console.log("CurrentUser", auth.currentUser);
// }
// console.log("CurrentUser", auth.currentUser);

auth.onAuthStateChanged(function (user) {
    if (user) {
        // console.log("onAuthStateChanged", user);
        lastUser = user;
        app?.ports?.loginSuccess?.send(user);
        isAdmin(user, () => {
            // console.log("isAdmin... loadPreviewKey");
            loadPreviewKey((key) => {
                app?.ports?.contentfulPreviewKeyFetched?.send(key);
            });
        });
        // User is signed in.
    } else {
        // console.log("onAuthStateChanged", "user logged out");
        app?.ports?.logoutSuccess?.send();
        app?.ports?.contentfulPreviewKeyFetched?.send("");
    }
});
